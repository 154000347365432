import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import authReducer from '../components/auth/authSlice'
import loginReducer from '../components/auth/loginSlice'

const persistAuthConfig = {
    key: 'auth',
    storage,
}

const persistedAuthReducer = persistReducer(persistAuthConfig, authReducer)

const store = configureStore({
    reducer: {
        auth: persistedAuthReducer,
        login: loginReducer,
    },
    middleware: [thunk],
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store