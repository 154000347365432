import React from 'react';
import './scss/App.scss';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './components/nav/NavBar';
import LoginPage from './components/auth/LoginPage';



function App() {
  return (
    <BrowserRouter>
            <NavBar />
            <Routes>
                <Route path='*' element={<div></div>} />
                <Route path='/identify' element={<LoginPage />} />
            </Routes>
        </BrowserRouter>
  );
}

export default App;
