import { Container, Nav, Navbar } from 'react-bootstrap'

import './NavBar.scss'

const NavBar = () => {

    return (
        <span>
            <Navbar>
                <Container>
                    <Nav>
                        <Nav.Link href='/identify'>Identify</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </span>
    )
}

export default NavBar
