import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type LoginState = {
    username: string | null,
    password: string | null,
}

const initialState: LoginState = {
    username: null,
    password: null,
}

export const loginSlice = createSlice({
    name: 'login',
    initialState: initialState,
    reducers: {
        setUsername: (state, action: PayloadAction<string>) => {
            state.username = action.payload
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload
        }
    }
})

export const { setUsername, setPassword } = loginSlice.actions

export default loginSlice.reducer