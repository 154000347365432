import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type AuthState = {
    isAuthenticated: boolean,
    idToken: string | null,
    accessToken: string | null,
    refreshToken: string | null,
    expiresAt: number | null
}

const initialState: AuthState = {
    isAuthenticated: false,
    idToken: null,
    accessToken: null,
    refreshToken: null,
    expiresAt: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setAuth: (state, action: PayloadAction<AuthState>) => {
            state.isAuthenticated = action.payload.isAuthenticated
            state.idToken = action.payload.idToken
            state.accessToken = action.payload.accessToken
            state.refreshToken = action.payload.refreshToken
            state.expiresAt = action.payload.expiresAt
        }
    }
})

export const { setAuth } = authSlice.actions

export default authSlice.reducer