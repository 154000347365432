
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setUsername, setPassword } from './loginSlice';

const LoginForm = () => {
    const dispatch = useAppDispatch()
    
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            console.log('submitting')
    }

    const changeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log('changing username')
        dispatch(setUsername(e.target.value))
    }

    const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log('changing password')
        dispatch(setPassword(e.target.value))
    }

    return (
        <form onSubmit={handleSubmit}>
        <h2>Login</h2>
        <label htmlFor="email">Email</label>
        <input
            type="email"
            id="email"
            onChange={changeUsername}
        />
        <label htmlFor="password">Password</label>
        <input
            type="password"
            id="password"
            onChange={changePassword}
        />
        <button type="submit">
            Login
        </button>
        </form>
    );
};

export default LoginForm;