import { useAppDispatch, useAppSelector } from '../../app/hooks'
import LoginForm from "./LoginForm"

const LoginPage = () => {
    const authStatus = useAppSelector(state => state.auth.isAuthenticated)

    let output

    if (authStatus) {
        output =  (<div>Already logged in</div>)
    } else {
        output = (<LoginForm />)
    }

    return (<div>{output}</div>)
}

export default LoginPage